/* start of side bar*/
.sidebar-container {
	
	width: 100%;
 
	height: 100vh;
	background-color: #000000d5;
	font-family: inherit;
}
.sidebar-container.khelodost,
.sidebar-container.aabetz{
background-color: var(--bg-gray-200);
}
.sidebar-top-dropdown {
	width: 100%;
	border-radius: 1px !important;
	height: 50px;
	background: white !important;
	color: black !important;
	font-size: 13px !important;
	font-weight: 700 !important;
	text-align: left !important;
	padding-left: 20px !important;
	border: none !important;
	box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.3) !important;
}

.sidebar-top-dropdown-menu {
	background: black !important;
	border: 2px solid green !important;
	border-radius: 10px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	margin-top: -10px !important;
	margin-left: 10px !important;
}

.sidebar-top-dropdown-menu .dropdown-item {
	color: #f9dc1c !important;
	font-size: 13px;
	font-weight: 400;
	padding-top: 5px;
	padding-bottom: 5px;
}

.dropdown-menu .dropdown-item:first-child {
	border-bottom: 2px solid green;
}

.sidebar-top-dropdown-icon {
	margin-right: 5px;
	font-size: 18px;
}

.SidebarWrap {
	width: 100%;
	height: 83vh;
	overflow-x: auto;
	overflow-y: auto;
}

.SidebarLink {
	display: flex;
	position: relative;
	color: white;
	justify-content: space-between;
	align-items: center;
	height: 45px;
	text-decoration: none;
	font-size: 16px;
	border-bottom: 1px solid #a19999;
}

a.SidebarLink:hover,
a.DropdownLink:hover,
a.NestedDropdownLink:hover {
	color: white;
}


.sidebar-icon {
	padding-top: 12px;
	padding-bottom: 14px;
	padding-right: 15px;
	padding-left: 15px;
}

.sidebar-icon {
	background: transparent;
	background-size: 100% 100%;
	transition: background-size 0.3s ease-in-out;
	width: 20px;
	height: 18px;
	font-size: 16px;
}

.SidebarLabel {
	margin-left: 10px;
	font-weight: 500;
}

.sidebar-icon-indicator {
	margin-right: 12px;
	padding: 0px 10px;
	border-radius: 20px;
	background-color: var(--gray-200);
	cursor: pointer;
}
.sidebar-container .d-flex{
background-color: var(--bg-gray-200);
}
.DropdownLink {
	background-color: var(--bg-gray-200);
	height: 45px;
	padding-left: 1rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: white;
	font-size: 15px;
}

.NestedDropdownLink {
	background-color: var(--bg-gray-200);
	height: 45px;
	padding-left: 1rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #f5f5f5;
	font-size: 14px;
}

.dropdown-icon {
	font-size: 16px;
}

.sidebar-tag {
	background: var(--dark-grey-box);
	color: white;
	padding: 10px 10px 10px 1em;
	font-size: 16px;
}