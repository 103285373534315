.rates .rate.back {
    background-color: #002859;
    --bs-table-bg: #002859;
    color:#fff !important;
}
.rates .rate.lay {
    background: #4B020B;
    --bs-table-bg: #4B020B;
    color:#fff !important;
}

.lay td,.back td{
    color:#fff !important;
}
 