/* Header Styles */
header {
	position: fixed;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	font-size: 14px;
	background-color: #000;
	z-index: 999;
	min-height: 56px;
}

header.khelodost,
header.aabetz{
background-color: var(--bg-primary) !important;
}

header p.username img {
	width: 26px;
	background-color: #ffffff4d;
	border-radius: 50%;
	margin-right: 6px;
}

header button.navbar-toggler {
	width: 28px;
}

header button.navbar-toggler .line {
	width: 100%;
	height: 2px;
	background: #fff;
	display: block;
}

header button.navbar-toggler .line:nth-child(2) {
	margin: 7px 0;
	width: 22px;
}

header button.navbar-toggler:focus {
	outline: none !important;
	box-shadow: unset !important;
}

header .logo_area {
	cursor: pointer;
}

header .logo_area img {
	max-width: 120px;
	width: 100% !important;
}

@media (min-width: 768px) {
	header .logo_area img {
		max-width: 160px;
	}
}

@media (min-width: 992px) {
	header .logo_area img {
		max-width: 200px;
	}
}

header .right_area .skewDiv {
	border-radius: 5px;
}

header .right_area .loginBtn {
	margin: 0 0 0 5px;
	max-width: fit-content;
	background-color: var(--bg-dark-800);
	text-transform: capitalize;
	font-size: 12px;
	margin-bottom: -25px;
}

header .right_area .signupBtn {
	background-color: var(--color-white);
	color: #000;
	border-radius: 5px;
}

header .right_area button {
	font-size: 13px;
}

@media (max-width: 575px) {
	header .right_area button {
		font-size: 12px;
	}
}

header .right_area button.depositBtn {
	padding: 5px;
	border-radius: 16px;
	border: 1px solid #f78800;
	color: #fff;
	background-color: #f78800;
}

header .right_area button.wallet_btn {
	padding: 5px 8px !important;
	margin-right: 4px;
	display: flex;
	align-items: center;
	font-weight: 600;
	color: #fff;
	border: 1px solid #000000;
	background: #000;
	border-radius: 12px;
}

@media (max-width: 369px) {
	header .right_area button.wallet_btn {
		padding: 3px !important;
		margin-left: 3px;
	}
}

header .right_area button.wallet_btn i {
	padding-right: 6px;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 767px) {
	header .right_area button.wallet_btn .txt {
		max-width: 100px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

header .right_area .account_navs {
	position: absolute;
	width: 190px;
	right: -10px;
	top: 47px;
	z-index: 1;
}

header .right_area .account_navs ul {
	box-shadow: 0 0 12px #4a4a4a1f;
}

header .right_area .account_navs ul li a {
	display: flex;
	font-size: 14px;
	padding: 7px 10px;
	border-bottom: 1px solid #e0e6e6;
	text-decoration: none;
	color: #000;
}

header .right_area .account_navs ul li a:hover {
	background-color: #355b62;
	color: var(--color-white);
}@media (max-width: 575px) {
	header .container {
		padding: 10px 6px !important;
		min-height: 60px;
	}
}
@media (max-width: 359px) {
	header .py-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
		padding-left: 4px;
		padding-right: 4px;
	}
}
@media screen and (max-width: 600px) {
	header .right_area .loginBtn {
		width: 82px !important;
	}
}